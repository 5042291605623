<template>
	<v-container fill-height fixed>
		<v-layout justify-center>
			<Progress :error="error" :loading="loading" type="overlay"></Progress>
			<v-flex xs12 v-if="tariffs">
				<material-card color="info" title="Оплата доступа">
					<template v-slot:headerRight>
						<v-btn outlined :to="{ name: 'billing-history' }">История платежей</v-btn>
					</template>
					<v-alert v-if="$route.query.success" type="success">
						Оплата прошла успешно
					</v-alert>
					<v-alert outlined :value="vip && vip.left ? true : false">
						Оплаченный период закончится
						<b>{{ vip.left }}</b>
						<b v-if="vip.date_until">({{ humanDate(vip.date_until) }})</b>.
						<span v-if="vip.date_until">При продлении они не сгорят.</span>
					</v-alert>
					<v-container grid-list-xl>
						<v-layout wrap v-if="!tariff">
							<v-flex md3 v-for="(Tariff, i) in tariffs" :key="i">
								<v-card outlined hover>
									<v-card-text>
										<v-layout column align-space-around class="text-center tariffs">
											<div class="name">
												<span>{{ Tariff.name }}</span>
												<!--<br />
												{{ Tariff.duration }} {{ countPlural(Tariff.duration, ['день', 'дня', 'дней']) }}-->
											</div>
											<div class="price">{{ Tariff.price }} &#8381;</div>
											<div v-if="i > 1" class="saving">
												<v-chip
													color="blue"
													dark
												>Экономия {{ Math.ceil((tariffs[1].price * i - Tariff.price) / 10) * 10 }} &#8381;</v-chip>
											</div>
											<div v-else></div>
											<div class="price-month">{{ Tariff.price / i }} &#8381; / месяц</div>
											<div>
												<v-btn @click="pay(i, Tariff)" color="success" dark :loading="loading_pay">
													<v-icon>icon-check</v-icon>Выбрать
												</v-btn>
											</div>
										</v-layout>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex xs12>
								<v-text-field
									@change="promoCheck"
									v-model="promo"
									label="Промокод"
									:success="promoSuccess"
									:loading="promoLoading"
									:error-messages="promoError"
									:success-messages="promoInfo"
									append-icon="icon-chevron_right"
									@click:append="promoCheck"
									@keypress.13.prevent="promoCheck"
									outlined
								></v-text-field>
							</v-flex>
						</v-layout>
						<v-layout v-else class="text-center">
							<v-flex>
								<v-btn outlined @click="tariff=null">Назад</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
					<v-container grid-list-xl>
						<v-layout wrap v-if="tariff" class="payment-container">
							<v-flex md4>
								<v-card outlined hover>
									<v-card-text>
										<v-layout column align-space-around class="text-center pay">
											<div class="name">Банковская карта</div>
											<div class="icon">
												<v-icon color="black" large>icon-credit-card</v-icon>
											</div>
											<div>
												<form
													ref="form"
													target="_blank"
													method="POST"
												>
													<v-btn @click="createTinkoffOrder" color="success">&nbsp;Оплатить</v-btn>
												</form>
											</div>
										</v-layout>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex md4>
								<v-card outlined hover>
									<v-card-text>
										<v-layout column align-space-around class="text-center pay">
											<div class="name">Яндекс.деньги</div>
											<div class="icon">
												<v-icon color="red">icon-yandex</v-icon>
											</div>
											<div>
												<form
													ref="form"
													target="_blank"
													method="POST"
													action="https://yoomoney.ru/quickpay/confirm.xml"
												>
													<input type="hidden" name="receiver" value="4100110301251239" />
													<input type="hidden" name="short-dest" :value="'Оплата счета #' + user_id" />
													<input type="hidden" name="targets" :value="'Оплата счета #' + user_id" />
													<input type="hidden" name="formcomment" value="Оплата счета atoto.ru" />
													<input type="hidden" name="label" :value="user_id + '|' + tariff.id" />
													<input type="hidden" name="quickpay-form" value="shop" />
													<input type="hidden" name="paymentType" value="PC" />
													<input type="hidden" data-type="number" name="sum" :value="tariff.price" />
													<v-btn @click="left('yandex')" color="success">Оплатить</v-btn>
												</form>
											</div>
										</v-layout>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex md4>
								<v-card outlined hover>
									<v-card-text>
										<v-layout column align-space-around class="text-center pay">
											<div class="name">Bitcoin</div>
											<div class="icon">
												<v-icon color="#f7931a" large>icon-bitcoin</v-icon>
											</div>
											<div>
												<form>
													<v-btn target="_blank" @click="createOrder" color="success">Оплатить</v-btn>
												</form>
											</div>
										</v-layout>
									</v-card-text>
								</v-card>
							</v-flex>
						</v-layout>
					</v-container>
				</material-card>
			</v-flex>
			<v-flex xs12 v-if="success">
				<v-alert type="success">Спасибо!</v-alert>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { Helper } from "../../mixins/Helper";

export default {
	mixins: [Helper],
	data() {
		return {
			loading: true,
			error: null,
			tariffs: null,
			info: null,
			vip: null,
			url: null,
			loading_pay: false,
			success: false,
			checkVipInterval: null,
			tariff: null,
			type: null,
			promoSuccess: false,
			promoError: "",
			promoLoading: false,
			promoInfo: "",
			promo: null
		};
	},
	created() {
		let t = this;
		document.title = "Оплата доступа";
		t.fetchData();
		t.goal('to_tariffs');
	},
	computed: {
		user_id() {
			return this.$store.state.user.user.id;
		}
	},
	methods: {
		fetchData() {
			let t = this;
			t.loading = true;

			window.ajax("/api/user/tariffs", {}, function(data, error) {
				t.loading = false;
				if (!error) {
					t.tariffs = data.tariffs;
					t.vip = data.vip;
					if (data.promo) {
						t.promo = data.promo;
						t.promoSuccess = true;
					}
				} else {
					t.error = data;
				}
			});
		},
		pay(i, tariff) {
			let t = this;
			//t.loading_pay = true;
			t.tariff = { id: i, price: tariff.price };
			t.$nextTick(() => {
				t.$vuetify.goTo(".pay");
			});
			t.goal('tariff_select');

			/*window.ajax("/api/order/create/" + i, {}, function(data, error) {
				t.loading_pay = false;
				if (!error) {
					t.url = data.url;
					console.log(t.url);
				} else {
					t.error = data;
				}
			});*/
		},
		createOrder() {
			let t = this;
			t.loading_pay = true;

			window.ajax("/api/order/create/" + t.tariff.id, {}, function(
				data,
				error
			) {
				t.loading_pay = false;
				if (!error) {
					window.open(data.url, "Оплата", "width=350,height=650") || window.location.assign(data.url);
					t.left("coinbase");
				} else {
					t.error = data;
				}
			});
		},
		createTinkoffOrder() {
			let t = this;
			t.loading_pay = true;

			window.ajax("/api/tinkoff/create/" + t.tariff.id, {}, function(
				data,
				error
			) {
				t.loading_pay = false;
				if (!error) {
					window.location.assign(data.url);
					t.left("tinkoff");
				} else {
					t.error = data;
				}
			});
		},
		checkVip() {
			let t = this;
			window.ajax("/api/user/get-vip", {}, function(data, error) {
				t.loading = false;
				if (!error && data.vip) {
					clearInterval(t.checkVipInterval);
					t.checkVipInterval = null;
					t.tariffs = [];
					t.vip = data.vip;
					t.success = false;
					t.goal('payment');
				}
			});
		},
		left(type) {
			this.goal('to_pay');
			if (type == "yandex") this.$refs.form.submit();
			if (type == "tinkoff") {
				this.$refs.form.submit();
			}
			//this.url = null;
			//this.success = true;
			//this.tariffs = null;
			this.checkVipInterval = setInterval(this.checkVip, 5000);
		},
		promoCheck() {
			let t = this;
			if (t.promo == t.promoPrev) return;
			t.promoPrev = t.promo;

			t.promoLoading = true;
			t.promoInfo = "";

			window.ajax("/api/promo/check/" + t.promo, {}, function(
				data,
				error
			) {
				t.promoLoading = false;
				if (error && !data.error) data.error = error;
				if (!data.error) {
					t.promoError = "";
					t.promoSuccess = true;
					t.promoLoading = false;
					if (data.tariffs) {
						t.tariffs = data.tariffs;
						t.tariff = null;
					}
					if (data.vip) {
						t.vip = data.vip;
					}
					if (data.info) {
						t.promoInfo = data.info;
					}
				} else {
					if (data.error == "not found")
						t.promoError = "Промокод не найден";
					else t.promoError = data.error;
				}
			});
		},
		goal(target) {
			if (this.$metrika) this.$metrika.reachGoal(target);
		}
	}
};
</script>
<style scoped>
.tariffs > div {
	min-height: 50px;
	padding: 10px;
}
.tariffs .name span {
	font-size: 36px;
}
.price {
	font-size: 20px;
}
.pay > div {
	min-height: 50px;
	padding: 10px;
}
.pay .name {
	font-size: 30px;
	line-height: 2rem;
}
</style>